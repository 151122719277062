import React, { useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useParams } from "react-router-dom"
import moment from 'moment'
import _ from 'lodash'

import PhotoAlbum from 'react-photo-album'

import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'

// import optional lightbox plugins
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen'
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow'
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import Video from 'yet-another-react-lightbox/plugins/video'
import 'yet-another-react-lightbox/plugins/thumbnails.css'

import styles from './styles.module.scss'
import Header from 'features/Shared/Header'

import InfiniteScroll from 'react-infinite-scroller'
import CircularProgress from '@mui/material/CircularProgress';

import { albumRequest } from 'app/Store/Slice/Album'

const AlbumScreen = () => {
    const dispatch = useDispatch()
    const params = useParams()

    const album = useSelector( state => { return state.album.data } )
    const messages = useSelector( state => { return state.album.messages }, shallowEqual )
    const messages2 = _.cloneDeep( messages );
    const pendingPages = useSelector( state => { return state.album.pendingPages } )  
    const [index, setIndex] = useState(-1)

    const images = [
        {
          key: 13,
          type: 'image',
          width: 300,
          height: 199,
          src: 'https://eu2.contabostorage.com/app-production-media-storage/event/1/65d682946c4b8_409856661418713.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205344Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=08dfd0ce7ca69d69e0dda1d68bd847a1f67d4d23353f8d69e17e04444fb481b7',
          srcSet: [
            {
              src: 'https://eu2.contabostorage.com/app-production-media-storage/media/cache/gallery_thumbnail/event/1/65d682946c4b8_409856661418713.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205344Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=bbb5bf425a05088068ba5851c95596188ace7ee67f9b1a249afaa067202ec953',
              width: 300,
              height: 199
            },
            {
              src: 'https://eu2.contabostorage.com/app-production-media-storage/event/1/65d682946c4b8_409856661418713.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205344Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=08dfd0ce7ca69d69e0dda1d68bd847a1f67d4d23353f8d69e17e04444fb481b7',
              width: 1600,
              height: 1066
            }
          ]
        },
        {
          key: 14,
          type: 'image',
          width: 300,
          height: 199,
          src: 'https://eu2.contabostorage.com/app-production-media-storage/event/1/65d682abdb275_907096820848757.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205344Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=ed2bcf8ada5ad17738303b13c112f6143dd54d2c14f4deb8e65a5963d4f8f2f5',
          srcSet: [
            {
              src: 'https://eu2.contabostorage.com/app-production-media-storage/media/cache/gallery_thumbnail/event/1/65d682abdb275_907096820848757.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205344Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=909ea0a90cd57db45c1c20c786b2c2e490862f519f1750fce35c65bf5edf74d7',
              width: 300,
              height: 199
            },
            {
              src: 'https://eu2.contabostorage.com/app-production-media-storage/event/1/65d682abdb275_907096820848757.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205344Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=ed2bcf8ada5ad17738303b13c112f6143dd54d2c14f4deb8e65a5963d4f8f2f5',
              width: 1600,
              height: 1066
            }
          ]
        },
        {
          key: 15,
          type: 'image',
          width: 300,
          height: 533,
          src: 'https://eu2.contabostorage.com/app-production-media-storage/event/1/65d682ff37ae5_1840162379769397.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205344Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=fbcc8ac01fad3c82a918a04576cd616e114c20ad667a609c2739812660fe35b0',
          srcSet: [
            {
              src: 'https://eu2.contabostorage.com/app-production-media-storage/media/cache/gallery_thumbnail/event/1/65d682ff37ae5_1840162379769397.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205344Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=af363c3f1ca3f46bde45f22ddede209e0444106078d9915acc16b0f1df801d07',
              width: 300,
              height: 533
            },
            {
              src: 'https://eu2.contabostorage.com/app-production-media-storage/event/1/65d682ff37ae5_1840162379769397.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205344Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=fbcc8ac01fad3c82a918a04576cd616e114c20ad667a609c2739812660fe35b0',
              width: 900,
              height: 1600
            }
          ]
        },
        {
          key: 16,
          type: 'image',
          width: 300,
          height: 201,
          src: 'https://eu2.contabostorage.com/app-production-media-storage/event/1/65d68308ebfb5_1110030480337617.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205344Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=4864aa24ba3950f0d7ceab3c6841d2fa6d112338cd95946d99e7fd29f73e0d1e',
          srcSet: [
            {
              src: 'https://eu2.contabostorage.com/app-production-media-storage/media/cache/gallery_thumbnail/event/1/65d68308ebfb5_1110030480337617.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205344Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=1245053a56574213dcef806812086115a4e1c119eabff8021e5aeeffa911c067',
              width: 300,
              height: 201
            },
            {
              src: 'https://eu2.contabostorage.com/app-production-media-storage/event/1/65d68308ebfb5_1110030480337617.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205344Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=4864aa24ba3950f0d7ceab3c6841d2fa6d112338cd95946d99e7fd29f73e0d1e',
              width: 1600,
              height: 1075
            }
          ]
        },
        {
          key: 17,
          type: 'image',
          width: 300,
          height: 200,
          src: 'https://eu2.contabostorage.com/app-production-media-storage/event/1/65d6830beb15a_388798487277299.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205344Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=80728c0ec1dfd1511b3ed3837b9226df6b2174ae2bc26c5b07431ebd3cbc611b',
          srcSet: [
            {
              src: 'https://eu2.contabostorage.com/app-production-media-storage/media/cache/gallery_thumbnail/event/1/65d6830beb15a_388798487277299.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205345Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=aa3f9b7330b013383154ea5f894cbe4418def2adfc29b2b1ac2d4b60ffb836a5',
              width: 300,
              height: 200
            },
            {
              src: 'https://eu2.contabostorage.com/app-production-media-storage/event/1/65d6830beb15a_388798487277299.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205344Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=80728c0ec1dfd1511b3ed3837b9226df6b2174ae2bc26c5b07431ebd3cbc611b',
              width: 1600,
              height: 1068
            }
          ]
        },
        {
          key: 18,
          type: 'image',
          width: 300,
          height: 199,
          src: 'https://eu2.contabostorage.com/app-production-media-storage/event/1/65d686eae587e_1422342658373685.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205345Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=f00a934860e17e2777b36650b272d52217bf68e730b2dde514ebdb2a67054d5b',
          srcSet: [
            {
              src: 'https://eu2.contabostorage.com/app-production-media-storage/media/cache/gallery_thumbnail/event/1/65d686eae587e_1422342658373685.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205345Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=06c0bebebb54b71a6f307b3a0ec665319c5fbf1c01618d572aa26168a4f8d51e',
              width: 300,
              height: 199
            },
            {
              src: 'https://eu2.contabostorage.com/app-production-media-storage/event/1/65d686eae587e_1422342658373685.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205345Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=f00a934860e17e2777b36650b272d52217bf68e730b2dde514ebdb2a67054d5b',
              width: 1600,
              height: 1066
            }
          ]
        },
        {
          key: 19,
          type: 'video',
          width: 300,
          height: 168,
          src: 'https://eu2.contabostorage.com/app-production-media-storage/media/cache/gallery_thumbnail/event/1/65d687a38c89f_mediaEdfclK.png?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205345Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=aaf0ca37312e3eea942fe9c6872453a10d1419250afebad3d63591a8354f1d2f',
          poster: 'https://eu2.contabostorage.com/app-production-media-storage/media/cache/gallery_thumbnail/event/1/65d687a38c89f_mediaEdfclK.png?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205345Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=aaf0ca37312e3eea942fe9c6872453a10d1419250afebad3d63591a8354f1d2f',
          sources: [
            {
              src: 'https://eu2.contabostorage.com/app-production-media-storage/event/1/65d687a1dad68_720665616875274.mp4?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205345Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=d50d11cb8da16934e94494df9cc03da1e25a7ade37bc32c5cdbc2e60a218242d',
              type: 'video/mp4'
            }
          ],
          srcSet: [
            {
              src: 'https://eu2.contabostorage.com/app-production-media-storage/media/cache/gallery_thumbnail/event/1/65d687a38c89f_mediaEdfclK.png?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205345Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=aaf0ca37312e3eea942fe9c6872453a10d1419250afebad3d63591a8354f1d2f',
              width: 300,
              height: 168
            }
          ],
          autoPlay: true
        },
        {
          key: 20,
          type: 'video',
          width: 300,
          height: 168,
          src: 'https://eu2.contabostorage.com/app-production-media-storage/media/cache/gallery_thumbnail/event/1/65d6880e2c12e_mediacGaHin.png?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205345Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=5863cafd5f6e01b2b5c32c17d39fa575c8dc64edcdffc5a611010ebd908240e2',
          poster: 'https://eu2.contabostorage.com/app-production-media-storage/media/cache/gallery_thumbnail/event/1/65d6880e2c12e_mediacGaHin.png?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205345Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=5863cafd5f6e01b2b5c32c17d39fa575c8dc64edcdffc5a611010ebd908240e2',
          sources: [
            {
              src: 'https://eu2.contabostorage.com/app-production-media-storage/event/1/65d6880d9faef_354933857508553.mp4?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205345Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=580f3b6e8a560cd12cb4840cdb74c368fb69a9f279694f4ae3fe1edae4738cf0',
              type: 'video/mp4'
            }
          ],
          srcSet: [
            {
              src: 'https://eu2.contabostorage.com/app-production-media-storage/media/cache/gallery_thumbnail/event/1/65d6880e2c12e_mediacGaHin.png?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205345Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=5863cafd5f6e01b2b5c32c17d39fa575c8dc64edcdffc5a611010ebd908240e2',
              width: 300,
              height: 168
            }
          ],
          autoPlay: true
        },
        {
          key: 21,
          type: 'image',
          width: 300,
          height: 199,
          src: 'https://eu2.contabostorage.com/app-production-media-storage/event/1/65d6880fd7eb1_374983782069594.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205345Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=2e4dc78dcc4207da721937def7fce7d92168d22b303ddfa5d72967c0378f5291',
          srcSet: [
            {
              src: 'https://eu2.contabostorage.com/app-production-media-storage/media/cache/gallery_thumbnail/event/1/65d6880fd7eb1_374983782069594.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205345Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=a594dd5086e2f976417349f5838ee0cc2f77b78a2f7c6b4ba3640a48a92c5a88',
              width: 300,
              height: 199
            },
            {
              src: 'https://eu2.contabostorage.com/app-production-media-storage/event/1/65d6880fd7eb1_374983782069594.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205345Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=2e4dc78dcc4207da721937def7fce7d92168d22b303ddfa5d72967c0378f5291',
              width: 1600,
              height: 1063
            }
          ]
        },
        {
          key: 22,
          type: 'image',
          width: 300,
          height: 225,
          src: 'https://eu2.contabostorage.com/app-production-media-storage/event/1/65d6887d9c220_315884548134118.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205345Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=0509c995ebecd4f319177539004105c6be311ab875994d38b66347cdf28b1b67',
          srcSet: [
            {
              src: 'https://eu2.contabostorage.com/app-production-media-storage/media/cache/gallery_thumbnail/event/1/65d6887d9c220_315884548134118.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205345Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=992c099cb8a10e91c4354efe3f8ef4e71c80bf9fe6ce3691d71c59a7ca23f7a8',
              width: 300,
              height: 225
            },
            {
              src: 'https://eu2.contabostorage.com/app-production-media-storage/event/1/65d6887d9c220_315884548134118.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205345Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=0509c995ebecd4f319177539004105c6be311ab875994d38b66347cdf28b1b67',
              width: 1600,
              height: 1200
            }
          ]
        },
        {
          key: 23,
          type: 'image',
          width: 300,
          height: 199,
          src: 'https://eu2.contabostorage.com/app-production-media-storage/event/1/65d6887fd0dd9_400604555890191.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205345Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=12c7caf5cfe05454c39a4fbbf634e7a939c15df8388e5cd4423159d5dfdaa41b',
          srcSet: [
            {
              src: 'https://eu2.contabostorage.com/app-production-media-storage/media/cache/gallery_thumbnail/event/1/65d6887fd0dd9_400604555890191.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205345Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=9ac378767bb1023f9360f45108fa5ceb0a064786174142db4a9e96f6feb9b43b',
              width: 300,
              height: 199
            },
            {
              src: 'https://eu2.contabostorage.com/app-production-media-storage/event/1/65d6887fd0dd9_400604555890191.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205345Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=12c7caf5cfe05454c39a4fbbf634e7a939c15df8388e5cd4423159d5dfdaa41b',
              width: 1600,
              height: 1066
            }
          ]
        },
        {
          key: 24,
          type: 'image',
          width: 300,
          height: 225,
          src: 'https://eu2.contabostorage.com/app-production-media-storage/event/1/65d68881bf400_381279441287074.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205345Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=2764f072122ff0149c396a4209f3956abdf81495f35e3960a89554c71f85384c',
          srcSet: [
            {
              src: 'https://eu2.contabostorage.com/app-production-media-storage/media/cache/gallery_thumbnail/event/1/65d68881bf400_381279441287074.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205345Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=4875941be0267ec3f74b70d44d3a6a375466d184190116b6f3c3572544ba288d',
              width: 300,
              height: 225
            },
            {
              src: 'https://eu2.contabostorage.com/app-production-media-storage/event/1/65d68881bf400_381279441287074.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=72b32b7b3ebbed5914de8522654860af%2F20240606%2F%25env%28DIGITALOCEAN_SPACES_REGION%29%2Fs3%2Faws4_request&X-Amz-Date=20240606T205345Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86400&X-Amz-Signature=2764f072122ff0149c396a4209f3956abdf81495f35e3960a89554c71f85384c',
              width: 1600,
              height: 1200
            }
          ]
        }
    ];

    return (
        <> 
            <div className={ styles['l-album'] }>
                <Header title={ album.name } />
                <div className={ styles['l-album__date'] }>{ moment(album.startDatetime).format("DD [de] MMMM [de] YYYY") }</div>
                <div className={ styles['l-album__container'] }>
                    <InfiniteScroll
                        pageStart={1}
                        loadMore={ ( page ) => { dispatch( albumRequest( { locator: params.locator, page: page } ) ) } }
                        hasMore={ pendingPages }
                        loader={ <CircularProgress className={ styles['l-album__loading'] } /> }
                        useWindow={false}
                    >
                        <PhotoAlbum 
                            layout="masonry" 
                            columns={ 2 }
                            photos={ messages2 } 
                            onClick={({ index }) => setIndex(index)}
                            renderPhoto={({ photo, wrapperStyle, renderDefaultPhoto }) => (
                                <div className={ photo.type === 'video' ? styles['l-album__video'] : styles['l-album__photo'] }>
                                    {renderDefaultPhoto({ wrapped: false })}
                                </div>
                            )}                        
                        />
                    </InfiniteScroll>

                    <Lightbox
                        slides={ messages2 }
                        open={index >= 0}
                        index={index}
                        close={() => setIndex(-1)}
                        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom, Video]}
                    />
                </div>
                <div className={ styles['l-album__copyright'] }>© 2023 Picpary - Todos los derechos reservados</div>
            </div>
        </>
    )
}

export default AlbumScreen;