import React from 'react'
import { useFormat } from 'app/Hook'
import { QRCode as QRBase } from 'react-qrcode-logo'

import styles from './styles.module.scss'

const QRCode = ( { phoneNumber } ) => {
    const formattedPhoneNumber = useFormat( phoneNumber.number, 'phone' )
    const showroomName = phoneNumber.name
    const event = phoneNumber.activeEvent

    if ( ! phoneNumber ) {
        return;
    }

    return (
        <div className={ styles['showroom-qr__wrapper'] }>
            <div className={ styles['showroom-qr__confirmation-code'] }>{ showroomName }</div>
            <div className={ styles['showroom-qr__content'] }>
                <div className={ styles['showroom-qr__title'] }>{ formattedPhoneNumber }</div>
                <QRBase
                    value={ `https://wa.me/+${ phoneNumber.number }` }
                    size={ 140 }
                    level="M"
                    fgColor="#444"
                />
                { event && 
                    <div className={ styles['showroom-qr__code'] }>{ event.confirmationCode }</div>
                }              
            </div>                
        </div>
    )
}

export default QRCode;