import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const PhoneNumberSlice = createSlice({
    name: 'phoneNumber',
    initialState,
    reducers: {
        setPhoneNumber: (state, action) => {
            return action.payload;
        }
    }
});

export const { setPhoneNumber } = PhoneNumberSlice.actions;

export default PhoneNumberSlice.reducer;