import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { setPhoneNumber } from 'app/Store/Slice/PhoneNumber';

const initialState = {
    sseToken: null,
    status: 'idle',
    error: false,
};

export const authorization = createAsyncThunk(
    'auth/authorization',
    async (data, thunkAPI) => {
        try {
            const response = await axios.request({
                url: '/api/phone_numbers/authorization',
                baseURL: window.APP_SERVICES_URL,
                method: 'POST',
                data: {
                    token: data.locator
                }
            })

            thunkAPI.dispatch(setPhoneNumber(response.data));
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({
                statusCode: error.response.status,
                statusText: error.response.statusText,
                message: error.message
            })
        }
    }
);

const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    extraReducers: {
        [authorization.pending]: (state, action) => {
            state.sseToken = null;
            state.status = 'authorization:loading';
            state.error = false;
        },
        [authorization.fulfilled]: (state, action) => {
            state.sseToken = action.payload.sseToken;
            state.status = 'authorization:succeeded';
            state.error = false;
        },
        [authorization.rejected]: (state, action) => {
            state.sseToken = null;
            state.status = 'authorization:failed';
            state.error = true;
        },
    }
});

export const { setSseToken } = AuthSlice.actions;

export default AuthSlice.reducer;