import { combineReducers, compose } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import AuthReducer from 'app/Store/Slice/Auth';
import PhoneNumberReducer from 'app/Store/Slice/PhoneNumber';
import ShowroomReducer from 'app/Store/Slice/Showroom';
import AlbumReducer from 'app/Store/Slice/Album';

const rootReducer  = combineReducers({
    auth: AuthReducer,
    phoneNumber: PhoneNumberReducer,
    showroom: ShowroomReducer,
    album: AlbumReducer,
});

const store = configureStore({
    reducer: rootReducer,
    middleware: [thunk],
});

export { store }