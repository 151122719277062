import React from 'react';
import Countdown from 'react-countdown';
import moment from 'moment';

import styles from './styles.module.scss';

const ChallengeTimer = ({ challenge, startDatetime }) => {
  const renderer = ({ formatted, completed }) => {
    return (
      <div className={styles['c-challenge-timer__countdown']}>{formatted.minutes}:{formatted.seconds}</div>
    );
  };

  return (
    <div className={styles['c-challenge-timer']} >
      <img src={challenge.icon.contentUrl} className={styles['c-challenge-timer__image']} alt="" />
      <div className={styles['c-challenge-timer__name']}>{challenge.name}</div>
      <div className={styles['c-challenge-timer__hashtag']}>{`#${challenge.hashtag}`}</div>
      <Countdown date={moment(startDatetime).add(30, 'm').toDate()} renderer={renderer} />
    </div >
  );
}

export default ChallengeTimer;