import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import { ThemeProvider } from '@mui/material/styles'

import theme from 'app/Theme'
import { store } from 'app/Store'

import AlbumContainer from 'features/AlbumContainer'
import ShowroomContainer from 'features/ShowroomContainer'
import NotFoundScreen from 'features/NotFoundScreen'

import 'App.scss';

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <CookiesProvider>
          <ThemeProvider theme={theme}>
            <Routes>
              {/*<Route path="/welcome-to-party" element={<AuthorizationScreen />} />*/}
              <Route path="/album/:locator" element={<AlbumContainer />} />
              <Route path="/:locator" element={<ShowroomContainer />} />
              <Route path="*" element={<NotFoundScreen />} />
            </Routes>
          </ThemeProvider>
        </CookiesProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
