import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    data: null,
    messages: [],
    pendingPages: true,
    status: 'idle',
    error: false,
};

export const albumRequest = createAsyncThunk(
    'album/request',
    async (data, thunkAPI) => {
        try {
            const response = await axios.request({
                url: `/api/events/album/${data.locator}`,
                baseURL: window.APP_SERVICES_URL,
                method: 'GET',
                params: {
                    maxPerPage: 10,
                    page: data.page
                }
            })

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue({
                statusCode: error.response.status,
                statusText: error.response.statusText,
                message: error.message
            })
        }
    }
);

const AlbumSlice = createSlice({
    name: 'album',
    initialState,
    reducers: {
        setAlbum: (state, action) => {
            return action.payload;
        }
    },
    extraReducers: {
        [albumRequest.pending]: (state, action) => { 
            state.status = 'request:loading';
            state.error = false;
        },
        [albumRequest.fulfilled]: (state, action) => {
            state.data = (({ pagedMessages, ...o }) => o)(action.payload);
            let photos = [];
            
            action.payload.pagedMessages.forEach(message => {
                let translatedMessage = {};

                translatedMessage.key = message.id;
                translatedMessage.type = message.type;
                translatedMessage.width = message.media.width;
                translatedMessage.height = message.media.height;

                switch ( message.type ) {
                    case 'video':
                        translatedMessage.src = message.media.thumbnailUrl;
                        translatedMessage.poster = message.media.thumbnailUrl;
                        translatedMessage.sources = [
                            {
                                src: message.media.contentUrl,
                                type: message.media.mimeType,
                            }
                        ];    
                        translatedMessage.srcSet = [
                            {
                                src: message.media.thumbnailUrl,
                                width: 300,
                                height: parseInt ( ( message.media.height / message.media.width ) * 300 ),
                            }
                        ];       
                        translatedMessage.autoPlay = true;                               
                        break;

                    default:
                        translatedMessage.src = message.media.contentUrl;
                        translatedMessage.srcSet = [
                            {
                                src: message.media.thumbnailUrl,
                                width: 300,
                                height: parseInt ( ( message.media.height / message.media.width ) * 300 ),
                            },
                            {
                                src: message.media.contentUrl,
                                width: message.media.width,
                                height: message.media.height,
                            },                            
                        ];
                }


                photos.push( translatedMessage );
            });

            state.messages = state.messages.concat( photos ).filter( Boolean );

            state.status = 'request:succeeded';
            state.error = false;
        },
        [albumRequest.rejected]: ( state, action ) => {
            if ( action.payload.statusCode === 404 ) {
                state.pendingPages = false;
            }
            state.status = 'request:failed';
            state.error = true;
        },
    }    
});

export const { setPhoneNumber } = AlbumSlice.actions;

export default AlbumSlice.reducer;