import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import ReactPlayer from 'react-player/file';
import moment from 'moment';
import 'moment/locale/es';

import { nextElement } from 'app/Store/Slice/Showroom';
import { useMediaStyles } from 'app/Hook';

import Author from 'features/Shared/Author'

import styles from './styles.module.scss';

const Video = ({ message, panelHeight }) => {
  const dispatch = useDispatch();
  const mediaStyles = useMediaStyles(panelHeight, message);
  const [imageStyle, setImageStyle] = useState({});
  const [messageExtra, setMessageExtra] = useState(null);
  const showedElements = useSelector(state => { return state.showroom.showedElements });

  useEffect(() => {
    let messageExtra = moment(message.createdAt).format('dddd[,] D [de] MMMM [a las] HH[:]mm');
    if (message.body) {
      messageExtra = message.body;
    }
    setMessageExtra(messageExtra);
  }, [message, showedElements]);

  useEffect(() => {
    if (Object.keys(mediaStyles).length === 0 && mediaStyles.height) {
      return;
    }

    setImageStyle(mediaStyles);
  }, [message, panelHeight]);

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        key={ message.id }
        initial={ { opacity: 0 } }
        animate={ { opacity: 1 } }
        exit={ { opacity: 0 } }
        transition={ { duration: 1 } }
      >
        <div className={styles['c-video']} style={{ height: imageStyle.height, margin: 'auto', background: imageStyle.background }}>
          <ReactPlayer url={ message.media.contentUrl } playing={ true } muted={ true } onEnded={ () => { dispatch(nextElement()) } } className={styles['c-video__player']} />
        </div>
        <Author attendant={ message.attendant } />
      </motion.div>
    </AnimatePresence>
  );
}

export default Video;
