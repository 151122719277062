import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
    palette: {
      primary: {
        main: '#C04B4B',
        contrastText: '#FFFFFF',
      },
      secondary: {
        main: '#001B2E',
        contrastText: '#ffffff',
      },
      white: {
        main: '#ffffff',
        contrastText: '#000',
      },
      gray: {
        light: '#f2f2f2',
        main: '#f2f2f2',
        dark: '#c2c2c2',
        contrastText: '#f2f2f2',
      }
    },
    typography: {
      fontFamily: '"Titillium Web", "Helvetica", sans-serif',
      h1: {
        fontSize: '2.5rem',
        fontWeight: 600,
      },
      h2: {
        fontSize: '1.875rem',
        fontWeight: 600,
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
            '&:active': {
              boxShadow: 'none',
            },
          }
        }
      }
    }
  });

  theme = responsiveFontSizes(theme);

  export default theme;