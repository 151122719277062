import React from 'react'

import styles from './styles.module.scss'

const Author = ({ attendant }) => {
    return (
        <div className={ styles['c-author'] }>
            <img className={ styles['c-author__avatar'] } src={ `https://api.dicebear.com/7.x/avataaars-neutral/svg?seed=${ attendant.phone }` } />
            <div className={ styles['c-author__name'] }>{ attendant.name }</div>
        </div>
    )
}

export default Author