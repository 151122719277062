import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";

import AuthorizationScreen from 'features/AuthorizationScreen';
import AlbumScreen from 'features/AlbumScreen';

import { albumRequest } from 'app/Store/Slice/Album';

const AlbumContainer = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const album = useSelector( state => { return state.album.data } );

    useEffect( () => {
        dispatch(albumRequest({locator: params.locator}));
    }, [] );

    if ( ! album ) {
        return <AuthorizationScreen />
    }

    return <AlbumScreen />
    
}

export default AlbumContainer;