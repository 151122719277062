import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import instructionsStep1 from 'assets/images/instructions-step-1.svg';
import instructionsStep2 from 'assets/images/instructions-step-2.svg';
import instructionsStep3 from 'assets/images/instructions-step-3.svg';

import { ELEMENT_DELAY_INSTRUCTIONS, nextElement } from 'app/Store/Slice/Showroom';
import useImagePreloader, { useFormat } from 'app/Hook';

import styles from './styles.module.scss';

const Instructions = ( { phoneNumber } ) => {
  const dispatch = useDispatch();
  const eventChallenge = useSelector( state => { return state.showroom.currentChallenge } );
  const showedElements = useSelector( state => { return state.showroom.showedElements } );

  const { imagesPreloaded } = useImagePreloader( [ instructionsStep1, instructionsStep2, instructionsStep3 ] );

  //const phone = useFormat( phoneNumber.number, 'phone' );
  const phone = '';

  useEffect( () => {
    setTimeout( () => {
      dispatch( nextElement() );
    }, ELEMENT_DELAY_INSTRUCTIONS )
  }, [ showedElements ] )

  if ( !! eventChallenge ) {
    return <ChallengeInstructions challenge={ eventChallenge.challenge } />
  }

  return <DefaultInstructions phone={ phone } />
}

const DefaultInstructions = ( { hashtag, phone } ) => {
  return (
    <div className={ styles['c-instructions'] }>
      <InstructionStepComponent 
        indication={`Envía por WhatsApp al <strong>${ phone }</strong> para poder comenzar a enviar tus fotos y vídeos`} 
        icon={ instructionsStep1 }
      />

      <InstructionStepComponent 
        indication={`Saca fotos y videos chulos y envíalas al <strong>${ phone }</strong>, recuerda que puedes añadir comentarios`} 
        icon={ instructionsStep2 }
      />

      <InstructionStepComponent 
        indication={'Todos podrán ver tus fotos y vídeos en esta pantalla y formarán parte de un álbum para el recuerdo'} 
        icon={ instructionsStep3 }
      />
    </div>
  )  
}

const InstructionStepComponent = ( { icon, indication } ) => {
  return (
    <div className={ styles['c-instructions-item'] }>
      <div className={ styles['c-instructions-item__icon-wrapper'] }>
        <div className={ styles['c-instructions-item__icon'] }>
          <img src={ icon } alt="" />
        </div>
      </div>
      <div className={ styles['c-instructions-item__description'] } dangerouslySetInnerHTML={ { __html: indication } }></div>
    </div>
  )
}

const ChallengeInstructions = ( { challenge } ) => {
  return (
      <div className={ styles['c-challenge-info'] }>
          <div className={ styles['c-challenge-info__title'] }>
            <img src={ challenge.icon.contentUrl } alt="" className={ styles['c-challenge-info__icon'] } />
            { challenge.name }
          </div>
          <div className={ styles['c-challenge-info__description'] }>
              { challenge.description }
          </div>
          <div className={ styles['c-challenge-info__instructions'] } dangerouslySetInnerHTML={ { __html: challenge.ctaDescription } }></div>
      </div>
  );
}

export default Instructions;