import React, { useEffect, useState } from 'react';
import { LightenDarkenColor } from 'lighten-darken-color';

export const useFormat = (value, format) => {
  let formattedValue = value;

  if (!value) {
    return '';
  }

  switch (format) {
    case 'phone':
      formattedValue = value
        .toString()
        .replace( '34', '' )
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  return formattedValue;
}

export const useMediaStyles = (panelHeight, message) => {
  let imageStyle = {};
  if (panelHeight === undefined || message === undefined) {
    return imageStyle;
  }

  imageStyle.height = panelHeight;
  let aspectRatio = imageStyle.height / message.media.height;
  console.log('imageStyle', imageStyle);
  console.log('media', message.media);
  console.log('aspectRatio', aspectRatio);
  imageStyle.width = message.media.width * aspectRatio;

  let mainColor = message.media.mainColor;
  let lightenMainColor = LightenDarkenColor(message.media.mainColor, 80);
  imageStyle.background = message.media.mainColor;
  imageStyle.background = `url(${message.media.contentUrl}), linear-gradient(to right bottom,  ${mainColor}, ${lightenMainColor})`;
  imageStyle.backgroundSize = 'cover';
  imageStyle.backgroundPostion = 'center';

  return imageStyle;
}

export const useAvatarConfiguration = (message) => {
  const topTypeOptions = [
    'NoHair',
    'Eyepatch',
    'Hat',
    'Hijab',
    'Turban',
    'WinterHat1',
    'WinterHat2',
    'WinterHat3',
    'WinterHat4',
    'LongHairBigHair',
    'LongHairBob',
    'LongHairBun',
    'LongHairCurly',
    'LongHairDreads',
    'LongHairFrida',
    'LongHairFro',
    'LongHairFroBand',
    'LongHairNotTooLong',
    'LongHairShavedSides',
    'LongHairMiaWallace',
    'LongHairStraight',
    'LongHairStraight2',
    'LongHairStraightStrand',
    'ShortHairDreads01',
    'ShortHairDreads02',
    'ShortHairFrizzle',
    'ShortHairShaggyMullet',
    'ShortHairShortCurly',
    'ShortHairShortFlat',
    'ShortHairShortRound',
    'ShortHairShortWaved',
    'ShortHairSides',
    'ShortHairTheCaesar',
    'ShortHairTheCaesarSidePart',
  ];

  const accessoriesTypeOptions = [
    'Blank',
    'Kurt',
    'Prescription01',
    'Prescription02',
    'Round',
    'Sunglasses',
    'Wayfarers',
  ];

  const hairColorOptions = [
    'Auburn',
    'Black',
    'Blonde',
    'BlondeGolden',
    'Brown',
    'BrownDark',
    'PastelPink',
    'Blue',
    'Platinum',
    'SilverGray',
  ];

  const facialHairTypeOptions = [
    'Blank',
    'BeardMedium',
    'BeardLight',
    'BeardMajestic',
    'MoustacheFancy',
    'MoustacheMagnum',
  ];

  const clotheTypeOptions = [
    'BlazerShirt',
    'BlazerSweater',
    'CollarSweater',
    'GraphicShirt',
    'Hoodie',
    'Overall',
    'ShirtCrewNeck',
    'ShirtScoopNeck',
    'ShirtVNeck',
  ];

  const clotheColorOptions = [
    'Black',
    'Blue01',
    'Blue02',
    'Blue03',
    'Gray01',
    'Gray02',
    'Heather',
    'PastelBlue',
    'PastelGreen',
    'PastelOrange',
    'PastelRed',
    'PastelYellow',
    'Pink',
    'Red',
    'White',
  ];

  const eyeTypeOptions = [
    'Close',
    'Cry',
    'Default',
    'Dizzy',
    'EyeRoll',
    'Happy',
    'Hearts',
    'Side',
    'Squint',
    'Surprised',
    'Wink',
    'WinkWacky',
  ];

  const mouthTypeOptions = [
    'Concerned',
    'Default',
    'Disbelief',
    'Eating',
    'Grimace',
    'Sad',
    'ScreamOpen',
    'Serious',
    'Smile',
    'Tongue',
    'Twinkle',
    'Vomit',
  ];

  const skinColorOptions = [
    'Tanned',
    'Yellow',
    'Pale',
    'Light',
    'Brown',
    'DarkBrown',
    'Black',
  ];

  const arbitraryElement = (options, seed) => {
    seed = (seed || '') + "xx"; // ensure a seed that can be reduced
    var charCodes = seed.split('').reduce(function (a, b, i) {
      return (i == 1 ? a.charCodeAt(0) : +a) + b.charCodeAt(0);
    });

    return options[charCodes % options.length]
  }

  return {
    avatarStyle: 'Circle',
    topType: arbitraryElement(topTypeOptions, message.attendant.phone),
    accessoriesType: arbitraryElement(accessoriesTypeOptions, message.attendant.phone),
    hairColor: arbitraryElement(hairColorOptions, message.attendant.phone),
    facialHairType: arbitraryElement(facialHairTypeOptions, message.attendant.phone),
    clotheType: arbitraryElement(clotheTypeOptions, message.attendant.phone),
    clotheColor: arbitraryElement(clotheColorOptions, message.attendant.phone),
    eyeType: arbitraryElement(eyeTypeOptions, message.attendant.phone),
    mouthType: arbitraryElement(mouthTypeOptions, message.attendant.phone),
    skinColor: arbitraryElement(skinColorOptions, message.attendant.phone),
  }
}

function preloadImage (src) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function() {
      resolve(img)
    }
    img.onerror = img.onabort = function() {
      reject(src)
    }
    img.src = src
  })
}

export default function useImagePreloader(imageList) {
  const [imagesPreloaded, setImagesPreloaded] = useState(false)

  useEffect(() => {
    let isCancelled = false;

    async function effect() {
      if (isCancelled) {
        return;
      }

      const imagesPromiseList = [];
      for (const i of imageList) {
        imagesPromiseList.push(preloadImage(i))
      }

      await Promise.all(imagesPromiseList)

      if (isCancelled) {
        return
      }

      setImagesPreloaded(true)
    }

    effect()

    return () => {
      isCancelled = true
    }
  }, [imageList])

  return { imagesPreloaded }
}
