import React from 'react';
import { useSelector } from 'react-redux';
import { TableContainer, Table, TableBody, TableRow, TableCell, Card, CardContent, Typography, TableHead } from '@mui/material';

import styles from './styles.module.scss';

const Debug = () => {
    const phoneNumber = useSelector( state => { return state.phoneNumber } );
    const showroom = useSelector( state => { return state.showroom } );

    return (
        <Card className={ styles['c-debug-panel'] } sx={ { padding: '0px' } }>
            <CardContent>
                <Typography sx={ { fontSize: 16, fontWeight: 700 } } color="text.secondary" align="left" mb={ 2 }>
                    Debug bar
                </Typography>

                <Typography sx={ { fontSize: 14 } } color="text.secondary" align="left" mb={ 2 } mt={ 3 }>
                    Information
                </Typography>
                <TableContainer>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell>Phone number ID</TableCell>
                                <TableCell align="right" sx={{ fontWeight: 'bold' }}>{phoneNumber['@id']}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Whatsapp Number</TableCell>
                                <TableCell align="right" sx={{ fontWeight: 'bold' }}>{phoneNumber['number']}</TableCell>
                            </TableRow>   
                            <TableRow>
                                <TableCell>Event ID</TableCell>
                                <TableCell align="right" sx={{ fontWeight: 'bold' }}>{phoneNumber.activeEvent.id}</TableCell>
                            </TableRow>    
                            <TableRow>
                                <TableCell>Event Name</TableCell>
                                <TableCell align="right" sx={{ fontWeight: 'bold' }}>{phoneNumber.activeEvent.name}</TableCell>
                            </TableRow>             
                            <TableRow>
                                <TableCell>Event Stard</TableCell>
                                <TableCell align="right" sx={{ fontWeight: 'bold' }}>{phoneNumber.activeEvent.startDatetime}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Event Name</TableCell>
                                <TableCell align="right" sx={{ fontWeight: 'bold' }}>{phoneNumber.activeEvent.endDatetime}</TableCell>
                            </TableRow>                                                                                              
                        </TableBody>
                    </Table>
                </TableContainer>                


                <Typography sx={ { fontSize: 14 } } color="text.secondary" align="left" mb={ 2 } mt={ 3 }>
                    Queue
                </Typography>

                <TableContainer>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell>Showed elements</TableCell>
                                <TableCell align="right" sx={{ fontWeight: 'bold' }}>{showroom.showedElements}</TableCell>
                            </TableRow>
                            { showroom.currentElement && (
                                <TableRow>
                                    <TableCell>Current Element Type</TableCell>
                                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{ showroom.currentElement.type }</TableCell>
                                </TableRow> 
                            ) }
                        </TableBody>
                    </Table>
                </TableContainer>
                &nbsp;
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>ID</TableCell>
                                <TableCell>Views</TableCell>
                                <TableCell>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { showroom.items.slice(0).reverse().map( ( element, index ) => {
                                return (
                                    <TableRow key={ index }>
                                        <TableCell>{ index + 1 }</TableCell>
                                        <TableCell>{ element?.type }</TableCell>
                                        <TableCell>{ element?.payload?.id }</TableCell>
                                        <TableCell>{ element?.views }</TableCell>
                                        <TableCell>{ element?.removable.toString() }</TableCell>
                                    </TableRow>    
                                )
                            } ) }
                        </TableBody>
                    </Table>                
                </TableContainer>
            </CardContent>
        </Card>

    );
}

export default Debug;