import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { EventSourcePolyfill } from 'event-source-polyfill';

import AuthorizationScreen from 'features/AuthorizationScreen';
import SetupScreen from 'features/SetupScreen';
import PresentationScreen from 'features/PresentationScreen';
import OnlineScreen from 'features/OnlineScreen';
import InitialScreen from 'features/InitialScreen';

import { addMessage, initializeShowroom } from 'app/Store/Slice/Showroom';
import { authorization } from 'app/Store/Slice/Auth';

const ShowroomContainer = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const tokenSSE = useSelector( state => { return state.auth.sseToken } );
    const phoneNumber = useSelector( state => { return state.phoneNumber } );
    const showroom = useSelector( state => { return state.showroom } );

    useEffect( () => {
        if ( ! tokenSSE ) {
            dispatch(authorization({locator: params.locator}));
            return;
        }

        let url = new URL( window.APP_MERCURE_URL );
        url.searchParams.append( 'topic', `phone-number-${ phoneNumber.number }` );
        url.searchParams.append( 'topic', `messages-from-phone-number-${ phoneNumber.number }` );
        const eventSource = new EventSourcePolyfill(url, {
            headers: {
                Authorization: `Bearer ${tokenSSE}`,
            },
        });

        if ( phoneNumber.activeEvent ) {
            dispatch( initializeShowroom( phoneNumber.activeEvent ) );
        }

        eventSource.addEventListener( 'message', ( event ) => {
            const data = JSON.parse( event.data );

            switch ( data['@type'] ) {
                case 'Message':
                    dispatch( addMessage( data ) );
                    break;
            }
            
        });
        eventSource.addEventListener( 'error', ( error ) => {});
        eventSource.addEventListener( 'open', ( event ) => {});
    }, [ tokenSSE ] );

    if ( ! tokenSSE ) {
        return <AuthorizationScreen />
    }

    return (
        <ShowroomElements phoneNumber={ phoneNumber } showroom={ showroom } />
    )
}

const ShowroomElements = ( { phoneNumber, showroom } ) => {
    if ( ! phoneNumber.activeEvent ) {
        return <PresentationScreen phoneNumber={ phoneNumber } />
    }

    if ( showroom.items.length === 0 ) {
        return <InitialScreen phoneNumber={ phoneNumber } />
    }

     switch ( phoneNumber.mode ) {
        case 'setup':
            return <SetupScreen phoneNumber={ phoneNumber } />
            break;
        
        case 'online':
            return <OnlineScreen phoneNumber={ phoneNumber } />
            break;
    }
}

export default ShowroomContainer;
