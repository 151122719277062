import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const ELEMENT_TYPE_IMAGE           = 'image';
export const ELEMENT_TYPE_VIDEO           = 'video';
export const ELEMENT_TYPE_INSTRUCTIONS    = 'instructions';
export const ELEMENT_TYPE_AD              = 'ad';

export const ELEMENT_DELAY_IMAGE          = 5000;
export const ELEMENT_DELAY_INSTRUCTIONS   = 5000;
export const MEDIAS_BETWEEN_INSTRUCTIONS  = 10;

export const MAX_ELEMENTS_IN_QUEUE = 5;
export const MEDIA_MINIMUN_VIEWS   = 0;

const initialState = {
    items: [],
    currentElement: null,
    showedElements: 0,
}

const createElementFromMessage = message => {
    return {
        type: message.type,
        views: 0,
        removable: false,
        createdAt: moment().toISOString(),
        payload: message
    };
}

const add = ( state, element ) => {
  state.items.unshift( element );
}

const next = state => {
  if ( ! state.items.length ) {
    return;
  }

  state.showedElements++;

  let lastElement = state.items.pop();
  if ( ! lastElement.removable ) {
    lastElement.views++;
    add( state, lastElement )
  }

  state.currentElement = state.items.at(-1);

  markRemovableElements( state );
}

const markRemovableElements = state => {
  let aliveElements = state.items.filter( item => {
    return item.removable === false;
  } )
  aliveElements.sort( ( i1, i2 ) => {
    if ( i1.views > i2.views ) {
      return 1;
    }

    if ( i2.views < i1.views ) {
      return -1;
    }

    if ( moment( i1.createdAt ) > moment( i2.createdAt ) ) {
      return 1;
    }

    if ( moment( i2.createdAt ) > moment( i1.createdAt ) ) {
      return -1;
    }

    return 0;
  } )

  if ( aliveElements.length < MAX_ELEMENTS_IN_QUEUE ) {
    return
  }

  let removableCandidates = aliveElements.slice( 0, aliveElements.length - MAX_ELEMENTS_IN_QUEUE );
  let removableElementIDs = removableCandidates.filter( element => {
    return element.views > MEDIA_MINIMUN_VIEWS;
  } ).map( element => {
    return element.payload.id
  } )

  state.items.forEach( ( element, index ) => {
    if ( removableElementIDs.includes( element.payload.id ) ) {
      state.items[ index ].removable = true
    }
  } )
}
  
const ShowroomSlice = createSlice( {
    name: 'showroom',
    initialState,
    reducers: {
        initializeShowroom: ( state, action ) => {
            let eventMessages = action.payload.lastMessages || [];
            eventMessages.forEach( message => {
                add( state, createElementFromMessage( message ) );
            } );

            next( state );
        },

        nextElement: ( state, action ) => {
          next( state );
        },
  
        addMessage: ( state, action ) => {
          let message = action.payload;
  
          add( state, createElementFromMessage( message ) );
          if ( state.currentElement === null ) {
            next( state );
          }
        }        
    },
} );

export const { initializeShowroom, nextElement, addMessage } = ShowroomSlice.actions;

export default ShowroomSlice.reducer;