import React from 'react';

import Header from 'features/Shared/Header';
import QRCode from 'features/Shared/QRCode';
import ChallengeTimer from 'features/OnlineScreen/ChallengeTimer';

import styles from './styles.module.scss';
import { ELEMENT_TYPE_INSTRUCTIONS } from 'app/Store/Slice/Showroom';

const LayoutChallenge = ({ children, event, eventChallenge, currentElement, enableFullScreen }) => {
    return (
        <div className={styles['l-challenge']} style={{ backgroundImage: `url(${eventChallenge.challenge.background.contentUrl})` }}>
            <Header event={event} />
            {(document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement) === undefined &&
                <div className={styles['l-challenge__fullscreen']} onClick={enableFullScreen} />
            }
            { children }
            <QRCode event={event} currentElement={currentElement} />
            <ChallengeTimer challenge={eventChallenge.challenge} startDatetime={eventChallenge.startDatetime} />
        </div>
    );
}

const ChallengeInfo = ({ challenge }) => {
    return (
        <div className={styles['c-challenge-info']}>
            <div className={styles['c-challenge-info__title']}>
              <img src={ challenge.icon.contentUrl } alt="" className={styles['c-challenge-info__icon']} />
              { challenge.name }
            </div>
            <div className={styles['c-challenge-info__description']}>
                { challenge.description }
            </div>
            <div className={styles['c-challenge-info__instructions']} dangerouslySetInnerHTML={{__html: challenge.ctaDescription }}></div>
        </div>
    );
}

export default LayoutChallenge;