import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { motion, AnimatePresence } from 'framer-motion'

import moment from 'moment'
import 'moment/locale/es'

import { nextElement, ELEMENT_DELAY_IMAGE } from 'app/Store/Slice/Showroom'
import { useMediaStyles } from 'app/Hook';

import styles from './styles.module.scss'
import Author from 'features/Shared/Author'

moment().locale('es');

const Image = ({ message, panelHeight }) => {
  const dispatch = useDispatch()
  const mediaStyles = useMediaStyles(panelHeight, message)
  const [imageStyle, setImageStyle] = useState({})
  const showedElements = useSelector(state => { return state.showroom.showedElements })

  useEffect(() => {
    setTimeout(() => {
      dispatch(nextElement())
    }, ELEMENT_DELAY_IMAGE)
  }, [message, showedElements])

  useEffect(() => {
    if (Object.keys(mediaStyles).length === 0) {
      return
    }

    setImageStyle(mediaStyles)
  }, [message, panelHeight])

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        key={message.id}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
        style={{ width: imageStyle.width }}      
      >
        <div className={styles['c-image']}>
          <div style={imageStyle}></div>
        </div>
        <Author attendant={ message.attendant } />
      </motion.div>
    </AnimatePresence>
  );
}

export default Image