import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import LayoutStandard from 'features/Shared/LayoutStandard';
import LayoutChallenge from 'features/OnlineScreen/LayoutChallenge';
import Instructions from 'features/OnlineScreen/Instructions';
import Image from 'features/OnlineScreen/Image';
import Video from 'features/OnlineScreen/Video';
import { 
  ELEMENT_TYPE_IMAGE,
  ELEMENT_TYPE_VIDEO,
  ELEMENT_TYPE_INSTRUCTIONS,
  ELEMENT_TYPE_AD,
} from 'app/Store/Slice/Showroom';

const OnlineScreen = ( { phoneNumber } ) => {
  const currentElement = useSelector( state => { return state.showroom.currentElement } );
  const currentChallenge = useSelector( state => { return state.showroom.currentChallenge } );
  const [ panelHeight, setPanelHeight ] = useState( 0 );

  if ( !! currentChallenge ) {
    return (
      <LayoutChallenge event={ phoneNumber.activeEvent } eventChallenge={ currentChallenge } >
        { !! currentElement && <ElementDisplay event={ phoneNumber } currentElement={ currentElement } panelHeight={ panelHeight } />}
      </LayoutChallenge>
    )
  }

  return (
    <LayoutStandard phoneNumber={ phoneNumber } setPanelHeight={ setPanelHeight }>
      { !! currentElement && <ElementDisplay event={ phoneNumber } currentElement={ currentElement } panelHeight={ panelHeight } />}
    </LayoutStandard>
  );
}

const ElementDisplay = ( { phoneNumber, currentElement, panelHeight } ) => {
  const showedElements = useSelector( state => { return state.showroom.showedElements } );

  if ( ! currentElement ) {
    return <></>;
  }

  switch ( currentElement.type ) {
    case ELEMENT_TYPE_IMAGE:
      return <Image message={ currentElement.payload } panelHeight={ panelHeight } key={ showedElements } />;

    case ELEMENT_TYPE_VIDEO:
      return <Video message={ currentElement.payload } panelHeight={ panelHeight } key={ showedElements } />;

    case ELEMENT_TYPE_INSTRUCTIONS:
      return <Instructions phoneNumber={ phoneNumber } />;

    case ELEMENT_TYPE_AD:
      return (<>Ad { Math.random() }</>);
  }
}

export default OnlineScreen;