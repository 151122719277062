import React from 'react';
import { Button } from '@mui/material';

import styles from './styles.module.scss';
import figure404 from 'assets/images/404-figure.svg';

const NotFoundPage = () => {
    return (
        <div className={styles['l-not-found-screen']}>
            <div className={styles['l-not-found-screen__content']}>
                <img src={figure404} alt="" className={styles['l-not-found-screen__figure']} />
                <div className={styles['l-not-found-screen__info']}>
                    <h1 className={styles['l-not-found-screen__title']}>Oops... ha ocurrido un problema</h1>
                    <div className={styles['l-not-found-screen__description']}>
                        La página que estás buscando parece que ya no existe, como has llegado aquí es un misterio. Puedes pulsar en el botón inferior para volver a la página principal.
                    </div>
                    <Button href="https://picpary.com" variant="outlined">Volver a la página principal</Button>
                </div>
            </div>
        </div>
    )
}

export default NotFoundPage;