import React from 'react';
import { useFormat } from 'app/Hook';

import styles from './styles.module.scss';
import logoPicpary from 'assets/images/logo-picpary.svg';
import classNames from 'classnames';

const Header = ( { phoneNumber, inversed = false, title = '' } ) => {
  const phone = useFormat(phoneNumber?.number, 'phone');

  return (
    <section className={ styles['c-header'] }>
      <img src={ logoPicpary } className={styles['c-header__logo']} alt="Picpary" />
      <div className={styles['c-header__info']}>
        {
          title !== '' ?
          title :
          <>
            Envía tus fotos y vídeos por Whatsapp al <strong>{phone}</strong>
          </>
        }
      </div>
    </section>
  )
}

export default Header;