import React, { useEffect, useRef } from 'react'
import { LightenDarkenColor } from 'lighten-darken-color'

import Header from 'features/Shared/Header'
import QRCode from 'features/Shared/QRCode'
import Debug from 'features/OnlineScreen/Debug'

import styles from './styles.module.scss'

const LayoutStandard = ( { children, phoneNumber, setPanelHeight } ) => {
    const standardLayout = useRef( null )
    const event = phoneNumber.activeEvent

    useEffect( () => {
        let style = getComputedStyle( standardLayout.current )
        let panelHeight = standardLayout.current.clientHeight - parseInt( style.paddingTop ) - parseInt( style.paddingBottom )
        setPanelHeight( panelHeight )
    } );

    return (
        <div className={ styles['c-showroom'] } 
             ref={ standardLayout } 
             style={ { background: `linear-gradient(52deg, ${ LightenDarkenColor( event.showroomBackground, 90)} 0%, ${event.showroomBackground} 100%)` }}
        >
            <Header phoneNumber={ phoneNumber } />
            {children}
            <QRCode phoneNumber={ phoneNumber } />
            { phoneNumber.debug && <Debug /> }
        </div>
    );
}

export default LayoutStandard;