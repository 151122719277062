import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss'

const SetupScreen = ({ phoneNumber }) => {
    return (
        <div className={styles['c-adjustment-chart']}>

            <div className={styles['c-adjustment-chart__arrow--t1']} />
            <div className={styles['c-adjustment-chart__arrow--t2']} />
            <div className={styles['c-adjustment-chart__arrow--l1']} />
            <div className={styles['c-adjustment-chart__arrow--l2']} />
            <div className={styles['c-adjustment-chart__arrow--r1']} />
            <div className={styles['c-adjustment-chart__arrow--r2']} />
            <div className={styles['c-adjustment-chart__arrow--b1']} />
            <div className={styles['c-adjustment-chart__arrow--b2']} />

            <div className={styles['c-adjustment-chart__content']}>
                <div className={styles['c-adjustment-chart__colors']}>
                    <div className={styles['c-adjustment-chart__colors--1']} />
                    <div className={styles['c-adjustment-chart__colors--2']} />
                    <div className={styles['c-adjustment-chart__colors--3']} />
                    <div className={styles['c-adjustment-chart__colors--4']} />
                    <div className={styles['c-adjustment-chart__colors--5']} />
                    <div className={styles['c-adjustment-chart__colors--6']} />
                    <div className={styles['c-adjustment-chart__colors--7']} />
                    <div className={styles['c-adjustment-chart__colors--8']} />
                </div>
                <div className={styles['c-adjustment-chart__grayscale']}>
                    <div className={styles['c-adjustment-chart__grayscale--1']} />
                    <div className={styles['c-adjustment-chart__grayscale--2']} />
                    <div className={styles['c-adjustment-chart__grayscale--3']} />
                    <div className={styles['c-adjustment-chart__grayscale--4']} />
                    <div className={styles['c-adjustment-chart__grayscale--5']} />
                    <div className={styles['c-adjustment-chart__grayscale--6']} />
                    <div className={styles['c-adjustment-chart__grayscale--7']} />
                    <div className={styles['c-adjustment-chart__grayscale--8']} />
                    <div className={styles['c-adjustment-chart__grayscale--9']} />
                    <div className={styles['c-adjustment-chart__grayscale--10']} />
                    <div className={styles['c-adjustment-chart__grayscale--11']} />
                    <div className={styles['c-adjustment-chart__grayscale--12']} />
                    <div className={styles['c-adjustment-chart__grayscale--13']} />
                    <div className={styles['c-adjustment-chart__grayscale--14']} />
                    <div className={styles['c-adjustment-chart__grayscale--15']} />
                    <div className={styles['c-adjustment-chart__grayscale--16']} />
                </div>
                <div className={styles['c-adjustment-chart__logo']} />
                <div className={styles['c-adjustment-chart__info']}>
                    <div className={styles['c-adjustment-chart__data']}>
                        <div className={styles['c-adjustment-chart__hashtag']}>{`${phoneNumber.number}`}</div>
                        <div className={styles['c-adjustment-chart__id']}>{phoneNumber['@id']}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SetupScreen;