import React from 'react';
import { useSelector } from 'react-redux';
import { Collapse, Alert, LinearProgress } from '@mui/material';

import logoPicpary from 'assets/images/logo-picpary.svg';
import styles from './styles.module.scss';

const AuthorizationScreen = () => {
  const auth = useSelector( state => state.auth );

  return (
    <div className={ styles['f-authorization'] }>
      <div className={ styles['f-authorization__block'] }>
        <img src={ logoPicpary } alt="Logo Picpary" className={ styles['f-authorization__block-logo'] } />
        <Collapse in={ !!auth.error }>
          <Alert severity="error" sx={ { mb: 2 } }>
              No ha sido posible recuperar la información, si el problema persiste póngase en contacto con nosotros
          </Alert>    
        </Collapse>
      </div>
      <Collapse className={styles['f-authorization__loader']} in={!auth.error}>
        <LinearProgress 
          color="primary"
          sx={{ height: 8 }}
        />
    </Collapse>
    </div>
  )
}

export default AuthorizationScreen;