import React from 'react';

import styles from './styles.module.scss';
import logoPicparyWhiteSquare from 'assets/images/logo-picpary-white-square.svg';

const PresentationScreen = ({ phoneNumber }) => {
  return (
    <div className={styles['c-presentation-screen']}>
      <div className={styles['c-presentation-screen__content']}>
        <div className={styles['c-presentation-screen__title']}>
          Bienvenido a Picpary
        </div>
        <div className={styles['c-presentation-screen__hashtag']}>
          Tus eventos más memorables.
        </div>
        <div className={styles['c-presentation-screen__countdown']}>
          <div className={styles['c-presentation-screen__welcome']}>
            Showroom listo para la acción, esperando un evento activo
          </div>
        </div>
      </div>
      <img className={styles['c-presentation-screen__logo']} src={logoPicparyWhiteSquare} alt="Logo Picpary" />
    </div >
  )
}

export default PresentationScreen;