import React, { useState } from 'react'
import { useFormat } from 'app/Hook'
import LayoutStandard from 'features/Shared/LayoutStandard'

import initialPicture from 'assets/images/initial-screen.svg'
import styles from './styles.module.scss'

const InitialScreen = ( { phoneNumber } ) => {
    const [ panelHeight, setPanelHeight ] = useState( 0 );
    const phone = useFormat(phoneNumber?.number, 'phone');

    return (
        <LayoutStandard phoneNumber={ phoneNumber } setPanelHeight={ setPanelHeight }>
            <div className={ styles['c-initial-screen'] }>
                <div className={ styles['c-initial-screen__figure'] }>
                    <img src={ initialPicture } alt="" />
                </div>
                <div className={ styles['c-initial-screen__content'] }>
                    <span className={ styles['c-initial-screen__title'] }>👋 ¡Hola!, ¿quieres dejarnos tu recuerdo?</span>
    
                    Haz la foto o vídeo mas loca, mas simpática, la más... y envianosla al <strong>{ phone }</strong> a través de <strong>Whatsapp</strong>. Todos podremos verla en en esta pantalla, ¿te anímas? 📷
                </div>
            </div>
        </LayoutStandard>
      )
}

export default InitialScreen;